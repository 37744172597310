import {Injectable} from "@angular/core";
import {Subject, BehaviorSubject, Observable} from "rxjs";
import {ScheduledSettings, PublisherSettings} from "./scheduled/scheduled-settings.interface";
import {UserService} from "@shared/user/user.service";
import {User} from "@shared/user/user.interface";
import {Tag} from "@shared/publisher/content.interface";
import {CreateUpdateContentTagComponent} from "../settings/tags/create-update-content-tag/create-update-content-tag.component";
import {PanelOverlayService} from "@overlay-panel/panel-overlay.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class PublisherSettingsService {
  public localStorageKeyPublisherSettings = "currentPublisherSettings";

  public libraryTagsSelected = new Subject<Tag[]>();

  public scheduledComponentSettings = new Subject<ScheduledSettings>();

  public publisherSettings = new BehaviorSubject<PublisherSettings>({
    menuCollapsed: false,
  });

  public isScheduledPublishingPaused = false;

  public loadingPublishingPausing = false;

  constructor(
    private snackbar: MatSnackBar,
    private userService: UserService,
    private panelOverlay: PanelOverlayService<CreateUpdateContentTagComponent>,
  ) {
    const storedPublisherSettings = JSON.parse(
      localStorage.getItem(this.localStorageKeyPublisherSettings),
    ) as PublisherSettings;

    if (storedPublisherSettings) {
      this.publisherSettings.next(storedPublisherSettings);
    }

    userService.me().subscribe((x) => (this.isScheduledPublishingPaused = x.IsPublishingPaused));
  }

  setSelectedTags(tag: Tag[] = null) {
    this.libraryTagsSelected.next(tag);
  }

  changeScheduledComponentSettings(ScheduledSettings) {
    this.scheduledComponentSettings.next(ScheduledSettings);
  }

  public openTagDialog() {
    this.panelOverlay
      .open(CreateUpdateContentTagComponent, {
        position: "right",
        data: {tag: null},
      })
      .afterClosed.subscribe((result: Tag) => {
        if (result) {
          this.snackbar.open(`${result.Name} content tag was successfully created.`);
        }
      });
  }

  changePublisherSettings(publisherSettings: PublisherSettings) {
    localStorage.setItem(this.localStorageKeyPublisherSettings, JSON.stringify(publisherSettings));
    this.publisherSettings.next(publisherSettings);
  }

  togglePublishingPausing() {
    this.loadingPublishingPausing = true;
    let observable: Observable<User>;
    if (this.isScheduledPublishingPaused) {
      observable = this.userService.resumeScheduledPublishing();
    } else {
      observable = this.userService.pauseScheduledPublishing();
    }

    observable.subscribe((user: User) => {
      this.isScheduledPublishingPaused = user.IsPublishingPaused;
      this.loadingPublishingPausing = false;
    });
    return observable;
  }
}
